import { PATH_CORP_FAQ } from "@capone/common";

export const PATH_HOME = "/";
// TODO: remove temporary basic auth once ready (see https://hopper-jira.atlassian.net/browse/BP-98)
export const PATH_BASIC_AUTH = PATH_HOME + "basicAuth/";
export const PATH_FLIGHTS = PATH_HOME + "flights/";
export const PATH_FLIGHTS_BOOK = PATH_FLIGHTS + "book/";
export const PATH_FLIGHTS_FREEZE = PATH_FLIGHTS + "freeze/";
export const PATH_FLIGHTS_FREEZE_OVERVIEW = PATH_FLIGHTS_FREEZE + "overview/";
export const PATH_FLIGHTS_FREEZE_CUSTOMIZE = PATH_FLIGHTS_FREEZE + "customize/";
export const PATH_FLIGHTS_FREEZE_CONFIRM = PATH_FLIGHTS_FREEZE + "confirm/";
export const PATH_FLIGHTS_SEARCH = PATH_FLIGHTS + "search/";
export const PATH_FLIGHTS_SHOP = PATH_FLIGHTS + "shop/";
export const PATH_FLIGHTS_BOOK_CONFIRMATION = PATH_FLIGHTS_BOOK + "confirm/";
export const PATH_FLIGHTS_BOOK_WITH_PF_CONFIRMATION =
  PATH_FLIGHTS_BOOK + "pricefreeze/confirm/";
export const PATH_FLIGHTS_BOOK_WITH_VI_CONFIRMATION =
  PATH_FLIGHTS_BOOK + "virtualInterline/confirm/";
export const PATH_DISRUPTION_OFFER = PATH_FLIGHTS + "disruption-offer/";
export const PATH_FLIGHTS_ANCILLARY = PATH_FLIGHTS + "ancillary/";
export const PATH_FLIGHTS_ANCILLARY_DISRUPTION =
  PATH_FLIGHTS_ANCILLARY + "disruption/";
export const PATH_FLIGHTS_ANCILLARY_DISRUPTION_CONFIRM =
  PATH_FLIGHTS_ANCILLARY_DISRUPTION + "confirm/";
export const PATH_HOTELS = PATH_HOME + "hotels/";
export const PATH_HOTELS_FREEZE = PATH_HOTELS + "freeze/";
export const PATH_HOTELS_FREEZE_CONFIRMATION = PATH_HOTELS_FREEZE + "confirm/";
export const PATH_HOTELS_BOOK = PATH_HOTELS + "book/";
export const PATH_HOTELS_SEARCH = PATH_HOTELS + "search/";
export const PATH_HOTELS_AVAILABILITY = PATH_HOTELS + "availability/";
export const PATH_HOTELS_SHOP = PATH_HOTELS + "shop/";
export const PATH_HOTELS_ANCILLARY = PATH_HOTELS + "ancillary/";
export const PATH_HOTELS_ANCILLARY_CUSTOMIZE =
  PATH_HOTELS_ANCILLARY + "customize/";
export const PATH_HOTELS_BOOK_CONFIRMATION = PATH_HOTELS_BOOK + "confirm/";

// The Stays paths are aliases for Hotels
export const PATH_STAYS = PATH_HOME + "stays/";
export const PATH_STAYS_SEARCH = PATH_STAYS + "search/";
export const PATH_STAYS_AVAILABILITY = PATH_STAYS + "availability/";

export const PATH_VACATION_RENTALS = PATH_HOME + "vacation-rentals/";

export const PATH_CARS = PATH_HOME + "cars/";
export const PATH_CARS_SEARCH = PATH_CARS + "search/";
export const PATH_CARS_AVAILABILITY = PATH_CARS + "availability/";
export const PATH_CARS_SHOP = PATH_CARS + "shop/";
export const PATH_CARS_BOOK = `${PATH_CARS}book/`;
export const PATH_CARS_BOOK_CONFIRMATION = PATH_CARS_BOOK + "confirm/";
export const PATH_TRIPS = PATH_HOME + "trips/";
export const PATH_TRIPS_DISRUPTION_PROTECTION_OVERVIEW =
  PATH_TRIPS + "disruption/";
export const PATH_TRIPS_DISRUPTION_PROTECTION_REFUND =
  PATH_TRIPS_DISRUPTION_PROTECTION_OVERVIEW + "refund/";
const PATH_TRIPS_DISRUPTION_PROTECTION_REBOOK =
  PATH_TRIPS_DISRUPTION_PROTECTION_OVERVIEW + "rebook/";
const PATH_TRIPS_DISRUPTION_PROTECTION_REBOOK_FLIGHT =
  PATH_TRIPS_DISRUPTION_PROTECTION_REBOOK + "flight/";
const PATH_TRIPS_DISRUPTION_PROTECTION_REBOOK_CONNECTION =
  PATH_TRIPS_DISRUPTION_PROTECTION_REBOOK + "connection/";
export const PATH_TRIPS_DISRUPTION_MCP_REBOOK_CONNECTING_FLIGHT = `${PATH_TRIPS_DISRUPTION_PROTECTION_OVERVIEW}rebook-connecting-flight/`;
export const TRIPS_PATH_HOTELS = PATH_TRIPS + "hotels/";
export const PATH_TRIPS_HOTELS_FREEZE = TRIPS_PATH_HOTELS + "freeze/";
export const PATH_HOTELS_FREEZE_OVERVIEW =
  PATH_TRIPS_HOTELS_FREEZE + "overview/";
export const PATH_TERMS = PATH_HOME + "terms/";
export const PRICE_DROP_PROTECTION = PATH_TERMS + "price-drop-protection/";
export const MISSED_CONNECTION_GUARANTEE =
  PATH_TERMS + "missed-connection-guarantee/";
export const BEST_PRICE_GUARANTEE = PATH_TERMS + "best-price-guarantee/";
export const PATH_TERMS_PRICE_FREEZE_FLIGHT = PATH_TERMS + "frozen-prices/";
export const PATH_TERMS_PRICE_FREEZE_HOTEL =
  PATH_TERMS + "frozen-prices-hotel/";
export const PRICE_PREDICTION_PRICE_WATCH =
  PATH_TERMS + "price-prediction-price-watch/";
export const PATH_TERMS_CFAR = PATH_TERMS + "cancel-for-any-reason";
export const PATH_TERMS_TRAVEL_CREDITS = PATH_TERMS + "travel-credits/";
export const PATH_TERMS_OF_SERVICE = PATH_HOME + "terms-of-service/";
export const PATH_SITE_TERMS_OF_USE = PATH_HOME + "site-terms-of-use/";
export const PATH_AUTH = `${PATH_HOME}auth/`;
export const PATH_AUTH_FTUX = `${PATH_AUTH}ftux/`;
export const PATH_AUTH_START_SESSION = `${PATH_AUTH}startsession/`;
export const PATH_AUTH_INVALID_SESSION = `${PATH_AUTH}invalidsession/`;
export const PATH_PREMIER_COLLECTION = PATH_HOME + "premium-stays/";
export const PATH_PREMIER_COLLECTION_SEARCH =
  PATH_PREMIER_COLLECTION + "search/";
export const PATH_PREMIER_COLLECTION_AVAILABILITY = `${PATH_PREMIER_COLLECTION}availability/`;
export const PATH_PREMIER_COLLECTION_ANCILLARY =
  PATH_PREMIER_COLLECTION + "ancillary/";
export const PATH_PREMIER_COLLECTION_ANCILLARY_CUSTOMIZE =
  PATH_PREMIER_COLLECTION_ANCILLARY + "customize/";
export const PATH_PREMIER_COLLECTION_BOOK = `${PATH_PREMIER_COLLECTION}book/`;
export const PATH_PREMIER_COLLECTION_BOOK_CONFIRMATION = `${PATH_PREMIER_COLLECTION_BOOK}confirm/`;
export const PATH_EXCHANGE = `${PATH_HOME}exchange`;
export const PATH_EXCHANGE_FLIGHT = `${PATH_EXCHANGE}/flight`;
export const PATH_EXCHANGE_FLIGHT_SHOP = `${PATH_EXCHANGE_FLIGHT}/shop`;
export const PATH_EXCHANGE_FLIGHT_CONFIRM = `${PATH_EXCHANGE_FLIGHT}/confirm`;
export const PATH_TRAVEL_WALLET = `${PATH_HOME}travel-offers/`;
export const PATH_TRAVEL_SALE = `/travelsale/`;
export const PATH_PREMIER_COLLECTION_SHOP = `${PATH_PREMIER_COLLECTION}shop/`;
export const PATH_PREMIER_VACATION_RENTALS = `${PATH_PREMIER_COLLECTION}vacation-rentals/`;
export const PATH_PREMIER_VACATION_RENTALS_AVAILABILITY = `${PATH_PREMIER_VACATION_RENTALS}availability/`;
export const PATH_PREMIER_VACATION_RENTALS_SHOP = `${PATH_PREMIER_VACATION_RENTALS}shop/`;
export const PATH_PREMIER_VACATION_RENTALS_SEARCH = `${PATH_PREMIER_VACATION_RENTALS}search/`;
export const PATH_PREMIER_VACATION_RENTALS_BOOK =
  PATH_PREMIER_VACATION_RENTALS + "book/";
export const PATH_PREMIER_VACATION_RENTALS_BOOK_CONFIRMATION =
  PATH_PREMIER_VACATION_RENTALS_BOOK + "confirm/";
export const PATH_CUSTOMER_PROFILE = `${PATH_HOME}profile/`;
export const PATH_PACKAGES = `${PATH_HOME}packages/`;
export const PATH_PACKAGES_SEARCH = `${PATH_PACKAGES}search/`;
export const PATH_PACKAGES_HOTEL_AVAILABILITY = `${PATH_PACKAGES}hotels-availability/`;
export const PATH_PACKAGES_BOOK = `${PATH_PACKAGES}book/`;
export const PATH_PACKAGES_BOOK_CONFIRMATION = `${PATH_PACKAGES_BOOK}confirm/`;
export const PATH_PACKAGE_HOTEL_SHOP = `${PATH_PACKAGES}hotel-shop/`;
export const PATH_PACKAGE_FLIGHT_SHOP = `${PATH_PACKAGES}flight-shop/`;
export const PATH_PACKAGE_REVIEW = `${PATH_PACKAGES}review/`;
export const PATH_VACATION_RENTALS_SHOP = `${PATH_VACATION_RENTALS}shop/`;
export const PATH_VACATION_RENTALS_BOOK = `${PATH_VACATION_RENTALS}book/`;
export const PATH_VACATION_RENTALS_BOOK_CONFIRMATION = `${PATH_VACATION_RENTALS_BOOK}confirm/`;

export const HIDDEN_FOOTER_PATHS = [
  PATH_HOTELS_AVAILABILITY,
  PATH_STAYS_AVAILABILITY,
  PATH_PREMIER_COLLECTION_AVAILABILITY,
  PATH_PREMIER_VACATION_RENTALS_AVAILABILITY,
  PATH_PACKAGES_HOTEL_AVAILABILITY,
  PATH_PACKAGE_HOTEL_SHOP,
  PATH_PACKAGE_FLIGHT_SHOP,
  PATH_PACKAGE_REVIEW,
];

// corporate travel
export const PATH_CORP_ACCESS_DENIED = `${PATH_HOME}access-denied/`;
export const PATH_CORP_ACCESS_DENIED_NOT_BETA = `${PATH_HOME}access-denied-not-beta/`;
export const PATH_CORP_NOT_LIVE = `${PATH_HOME}not-live/`;

export const HIDDEN_FOOTER_PATHS_MOBILE = [
  PATH_PREMIER_COLLECTION_SEARCH,
  PATH_HOTELS_SHOP,
  PATH_HOTELS_ANCILLARY_CUSTOMIZE,
  PATH_HOTELS_FREEZE,
  PATH_FLIGHTS_SHOP,
  PATH_FLIGHTS_FREEZE,
  PATH_CARS_SHOP,
  PATH_PREMIER_COLLECTION_SHOP,
  PATH_PREMIER_COLLECTION_ANCILLARY_CUSTOMIZE,
  PATH_PREMIER_VACATION_RENTALS_SEARCH,
  PATH_PREMIER_VACATION_RENTALS_SHOP,
  PATH_PREMIER_VACATION_RENTALS_AVAILABILITY,
  PATH_VACATION_RENTALS_SHOP,
  PATH_PACKAGES_SEARCH,
];

export const HIDDEN_FOOTER_UNPROTECTED_PATHS = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
];

export const NO_FOOTER_PADDING_PATHS: string[] = [PATH_PACKAGES_SEARCH];

export const NO_FOOTER_PADDING_UNPROTECTED_PATHS: string[] = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
];

export const HIDDEN_HEADER_PATHS_DESKTOP = [
  `${PATH_FLIGHTS}shop/`,
  `${PATH_FLIGHTS}book/`,
  PATH_FLIGHTS_FREEZE,
  PATH_DISRUPTION_OFFER,
  PATH_HOTELS_AVAILABILITY,
  PATH_HOTELS_SHOP,
  PATH_HOTELS_ANCILLARY_CUSTOMIZE,
  PATH_HOTELS_BOOK,
  PATH_STAYS_AVAILABILITY,
  PATH_PREMIER_VACATION_RENTALS_BOOK,
  PATH_VACATION_RENTALS_BOOK,
  PATH_CARS_AVAILABILITY,
  PATH_CARS_BOOK,
  `${PATH_CARS}shop/`,
  PATH_FLIGHTS_FREEZE_OVERVIEW,
  PATH_FLIGHTS_FREEZE_CUSTOMIZE,
  `${PATH_EXCHANGE}/`,
  `${PATH_EXCHANGE_FLIGHT}/`,
  `${PATH_EXCHANGE_FLIGHT_SHOP}/`,
  `${PATH_EXCHANGE_FLIGHT_CONFIRM}/`,
  PATH_TRIPS_DISRUPTION_PROTECTION_OVERVIEW,
  PATH_TRIPS_DISRUPTION_MCP_REBOOK_CONNECTING_FLIGHT,
  PATH_TRIPS_DISRUPTION_PROTECTION_REFUND,
  PATH_TRIPS_DISRUPTION_PROTECTION_REBOOK_FLIGHT,
  PATH_TRIPS_DISRUPTION_PROTECTION_REBOOK_CONNECTION,
  PATH_HOTELS_FREEZE,
  PATH_HOTELS_FREEZE_OVERVIEW,
  PATH_PREMIER_COLLECTION_AVAILABILITY,
  PATH_PREMIER_COLLECTION_SHOP,
  PATH_PREMIER_COLLECTION_ANCILLARY_CUSTOMIZE,
  PATH_PREMIER_COLLECTION_BOOK,
  PATH_PREMIER_VACATION_RENTALS_AVAILABILITY,
  PATH_PREMIER_VACATION_RENTALS_SHOP,
  PATH_VACATION_RENTALS_SHOP,
  PATH_PACKAGES_HOTEL_AVAILABILITY,
  PATH_PACKAGES_BOOK,
  PATH_PACKAGE_HOTEL_SHOP,
  PATH_PACKAGE_FLIGHT_SHOP,
  PATH_PACKAGE_REVIEW,
];

export const HIDDEN_ROOT_BANNER_PATHS = [PATH_TRAVEL_SALE];

export const CAPONE_HEADER_UPDATED_STYLING_SEARCH_PATHS = [
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS_SEARCH,
  PATH_STAYS_SEARCH,
  PATH_CARS_SEARCH,
];

export const CAPONE_HEADER_UPDATED_STYLING_PATHS = [
  `${PATH_FLIGHTS}shop/`,
  `${PATH_FLIGHTS}book/`,
  PATH_FLIGHTS_FREEZE,
  PATH_HOTELS_AVAILABILITY,
  PATH_HOTELS_SHOP,
  PATH_STAYS_AVAILABILITY,
  PATH_HOTELS_ANCILLARY_CUSTOMIZE,
  PATH_CARS_AVAILABILITY,
  `${PATH_CARS}shop/`,
  PATH_FLIGHTS_FREEZE_OVERVIEW,
  PATH_FLIGHTS_FREEZE_CUSTOMIZE,
  `${PATH_EXCHANGE}/`,
  `${PATH_EXCHANGE_FLIGHT}/`,
  `${PATH_EXCHANGE_FLIGHT_SHOP}/`,
  `${PATH_EXCHANGE_FLIGHT_CONFIRM}/`,
  PATH_PREMIER_COLLECTION_AVAILABILITY,
  PATH_PREMIER_COLLECTION_SHOP,
  PATH_PREMIER_COLLECTION_ANCILLARY_CUSTOMIZE,
  PATH_HOTELS_FREEZE,
  PATH_HOTELS_FREEZE_OVERVIEW,
  PATH_PREMIER_VACATION_RENTALS_AVAILABILITY,
  PATH_PREMIER_VACATION_RENTALS_SHOP,
  PATH_PACKAGES_HOTEL_AVAILABILITY,
  PATH_PACKAGE_HOTEL_SHOP,
  PATH_PACKAGE_FLIGHT_SHOP,
  PATH_PACKAGE_REVIEW,
];

export const HIDDEN_HEADER_PATHS_MOBILE_GLOBAL_NAV = [
  `${PATH_FLIGHTS}book/`,
  PATH_HOTELS_BOOK,
  PATH_PREMIER_VACATION_RENTALS_BOOK,
  PATH_VACATION_RENTALS_BOOK,
  PATH_PREMIER_COLLECTION_BOOK,
  PATH_CARS_BOOK,
  PATH_PACKAGES_SEARCH,
  PATH_PACKAGES_BOOK,
  PATH_FLIGHTS_FREEZE_OVERVIEW,
  PATH_FLIGHTS_FREEZE_CUSTOMIZE,
  PATH_FLIGHTS_FREEZE,
];

export const HIDDEN_HEADER_PATHS_MOBILE = [
  `${PATH_FLIGHTS}shop/`,
  `${PATH_FLIGHTS}book/`,
  `${PATH_FLIGHTS}book/`,
  PATH_FLIGHTS_FREEZE,
  PATH_HOTELS_AVAILABILITY,
  PATH_HOTELS_SHOP,
  PATH_HOTELS_ANCILLARY_CUSTOMIZE,
  PATH_HOTELS_BOOK,
  PATH_STAYS_AVAILABILITY,
  PATH_PREMIER_VACATION_RENTALS_BOOK,
  PATH_VACATION_RENTALS_BOOK,
  PATH_CARS_AVAILABILITY,
  PATH_CARS_BOOK,
  `${PATH_CARS}shop/`,
  PATH_FLIGHTS_FREEZE_OVERVIEW,
  PATH_FLIGHTS_FREEZE_CUSTOMIZE,
  `${PATH_EXCHANGE}/`,
  `${PATH_EXCHANGE_FLIGHT}/`,
  `${PATH_EXCHANGE_FLIGHT_SHOP}/`,
  `${PATH_EXCHANGE_FLIGHT_CONFIRM}/`,
  PATH_PREMIER_COLLECTION_AVAILABILITY,
  PATH_PREMIER_COLLECTION_SHOP,
  PATH_PREMIER_COLLECTION_ANCILLARY_CUSTOMIZE,
  PATH_PREMIER_COLLECTION_BOOK,
  PATH_HOTELS_FREEZE,
  PATH_HOTELS_FREEZE_OVERVIEW,
  PATH_PREMIER_VACATION_RENTALS_AVAILABILITY,
  PATH_PREMIER_VACATION_RENTALS_SHOP,
  PATH_VACATION_RENTALS_SHOP,
  PATH_PACKAGES_HOTEL_AVAILABILITY,
  PATH_PACKAGES_BOOK,
  PATH_PACKAGE_HOTEL_SHOP,
  PATH_PACKAGE_FLIGHT_SHOP,
  PATH_PACKAGE_REVIEW,
];

export const DISPLAY_HOMEPAGE_TAKEOVER_PATHS = [
  PATH_FLIGHTS,
  PATH_HOTELS,
  PATH_STAYS,
  PATH_CARS,
  PATH_HOME,
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS_SEARCH,
  PATH_STAYS_SEARCH,
  PATH_CARS_SEARCH,
  PATH_PREMIER_COLLECTION,
  PATH_PREMIER_COLLECTION_SEARCH,
  PATH_PACKAGES,
];

export const UNPROTECTED_PATHS = [
  PATH_AUTH,
  PATH_TERMS,
  PATH_TERMS_OF_SERVICE,
  PATH_SITE_TERMS_OF_USE,
  PRICE_DROP_PROTECTION,
  MISSED_CONNECTION_GUARANTEE,
  BEST_PRICE_GUARANTEE,
  PRICE_PREDICTION_PRICE_WATCH,
  PATH_TERMS_PRICE_FREEZE_FLIGHT,
  PATH_TERMS_PRICE_FREEZE_HOTEL,
  PATH_CORP_NOT_LIVE,
  PATH_CORP_ACCESS_DENIED,
  PATH_CORP_ACCESS_DENIED_NOT_BETA,
  PATH_CORP_FAQ,
];

export const HIDDEN_HEADER_UNPROTECTED_PATHS = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
];

export const HIDDEN_BANNER_UNPROTECTED_PATHS = [
  PATH_AUTH_FTUX,
  PATH_AUTH_START_SESSION,
  PATH_AUTH_INVALID_SESSION,
  ...UNPROTECTED_PATHS,
];

export const HIDDEN_HEADER_DETAILS_PATHS = [
  PATH_CORP_NOT_LIVE,
  PATH_CORP_ACCESS_DENIED,
  PATH_CORP_ACCESS_DENIED_NOT_BETA,
];

export const POSITION_FIXED_FOOTER_PATHS: string[] = [];

export const PATHS_WITH_FIXED_RESERVED_COMPONENT: string[] = [
  PATH_CARS_SHOP,
  PATH_HOTELS_SHOP,
];

export const PRICE_MATCH_BANNER_PATHS = [
  PATH_CARS_BOOK,
  PATH_CARS_BOOK_CONFIRMATION,
  PATH_HOTELS_BOOK,
  PATH_HOTELS_BOOK_CONFIRMATION,
  PATH_FLIGHTS_BOOK,
  PATH_FLIGHTS_BOOK_CONFIRMATION,
  PATH_FLIGHTS_ANCILLARY_DISRUPTION_CONFIRM,
  PATH_FLIGHTS_FREEZE_CONFIRM,
  PATH_HOTELS_FREEZE_CONFIRMATION,
  PATH_PREMIER_VACATION_RENTALS_BOOK,
  PATH_PREMIER_VACATION_RENTALS_BOOK_CONFIRMATION,
];

export const HIDDEN_FUNNEL_ENTRY_HEADER_PATHS = [
  PATH_CARS_BOOK_CONFIRMATION,
  PATH_HOTELS_BOOK_CONFIRMATION,
  PATH_PREMIER_VACATION_RENTALS_BOOK_CONFIRMATION,
  PATH_VACATION_RENTALS_SHOP,
  PATH_VACATION_RENTALS_BOOK,
  PATH_VACATION_RENTALS_BOOK_CONFIRMATION,
  PATH_FLIGHTS_BOOK_CONFIRMATION,
  PATH_FLIGHTS_BOOK_WITH_PF_CONFIRMATION,
  PATH_FLIGHTS_BOOK_WITH_VI_CONFIRMATION,
  PATH_PREMIER_COLLECTION_BOOK_CONFIRMATION,
  PATH_FLIGHTS_ANCILLARY_DISRUPTION_CONFIRM,
  PATH_FLIGHTS_FREEZE_CONFIRM,
  PATH_HOTELS_FREEZE_CONFIRMATION,
  PATH_TRIPS,
  PATH_TRAVEL_WALLET,
  PATH_TRAVEL_SALE,
  PATH_AUTH,
  PATH_HOTELS_ANCILLARY_CUSTOMIZE,
  PATH_PREMIER_COLLECTION_ANCILLARY_CUSTOMIZE,
  PATH_CUSTOMER_PROFILE,
  PATH_PACKAGES_BOOK_CONFIRMATION,
];

export const ADDITIONAL_MOBILE_REDESIGN_HEADER_FOOTER_PATHS = [
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS_SEARCH,
  PATH_STAYS_SEARCH,
  PATH_CARS_SEARCH,
  PATH_PREMIER_COLLECTION_SEARCH,
];

export const PREMIER_COLLECTION_BANNER_STYLE_PATHS = [
  PATH_PREMIER_COLLECTION,
  PATH_PREMIER_COLLECTION_AVAILABILITY,
  PATH_PREMIER_COLLECTION_SHOP,
  PATH_PREMIER_COLLECTION_ANCILLARY_CUSTOMIZE,
  PATH_PREMIER_COLLECTION_BOOK,
];

export const HOME_PAGE_PATHS = [
  PATH_FLIGHTS,
  PATH_HOTELS,
  PATH_STAYS,
  PATH_CARS,
  PATH_HOME,
  PATH_PACKAGES,
];

export const DISPLAY_CROSS_SELL_AND_RV_PATHS = [...HOME_PAGE_PATHS];
